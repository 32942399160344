<template>
  <div>
    <a-spin v-if="!bannerAdData" class="d-flex justify-content-center align-items-center ">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
    </a-spin>
    <div v-else class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Banner Ad</strong>
            </div>
            <div class="text-muted">Edit Banner Ad information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Banner Ad Name" v-decorator="['name', {
      initialValue: bannerAdData.Name,
      rules: [
        { required: true, message: 'Name is required.' },
        { max: 64, message: 'Maximum 64 characters allowed.' }
      ]
    }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPM">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per mile.</span>
                      </template>
                      <a-input type="number" min="0" max="100" step="0.0001" placeholder="Default CPM"
                        @change="updateDefaultCPM"
                        v-decorator="['defaultCPM', { initialValue: Math.round((bannerAdData.DefaultCPM + Number.EPSILON) * 10000) / 10000, rules: [{ validator: defaultCpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your Banner Ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['adDomainBrand', {
      initialValue: bannerAdData.AdDomainBrand,
      rules: [
        { max: 256, message: 'Maximum 256 characters allowed.' }
      ]
    }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Application identifier. On Android, this should be bundle or package name (e.g.,
                          com.foo.mygame), on iOS, this is numeric ID.</span>
                      </template>
                      <a-input type="text" placeholder="Ad App Bundle" v-decorator="['adAppBundle', {
      initialValue: bannerAdData.AdAppBundle,
      rules: [
        { max: 64, message: 'Maximum 64 characters allowed.' }
      ]
    }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your Banner Ad.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
      initialValue: bannerAdData.DestinationUrl,
      rules: [
        { required: true, message: 'Destination URL is required.' },
        { max: 512, message: 'Maximum 512 characters allowed.' }
      ]
    }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{width}</b> - Width will be replaced.<br>
                            <b>{height}</b> - Height will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order
                            to prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                </div>
              </div>

              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout
                          the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: bannerAdData.BidsDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions
                          throughout
                          the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: bannerAdData.ImpressionsDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks
                          throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                        v-decorator="['ClicksDailyLimit', { initialValue: bannerAdData.ClicksDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Banner Ad. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Spent Daily Limit"
                        v-decorator="['SpentDailyLimit', { initialValue: bannerAdData.SpentDailyLimit }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply</a-divider>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Banner Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Banner Zones to connect to the Banner Ad. This will make the Banner Ad appear for Banner Zone
                      if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Bannerzones', { initialValue: (bannerAdData.Bannerzones === '' ? [] : bannerAdData.Bannerzones) }]"
                    placeholder="Please select Banner Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option v-for="obj in activeBannerzones" class="zones" :key="obj.Id">[{{ obj.Id }}] {{
      obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>

              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="RTB Zones">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>RTB Zones to connect to the Banner Ad. This will make the Banner Ad appear in RTB Zone
                      response if all the targeting is matched.</span>
                  </template>
                  <a-select
                    v-decorator="['Rtbzones', { initialValue: (bannerAdData.Rtbzones === '' ? [] : bannerAdData.Rtbzones) }]"
                    placeholder="Please select RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                    optionFilterProp="children">
                    <a-select-option v-for="obj in activeRTBZones" class="zones" :key="obj.Id">[{{ obj.Id }}] {{
      obj.Name }}
                    </a-select-option>
                  </a-select>
                </a-tooltip>
              </a-form-item>

              <a-divider style="margin-top: 34px;">Banner Position</a-divider>

              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="bannerPosition" />
              <!-- <a-divider style="margin-top: 34px;" class=" mb-4">Creatives</a-divider> -->
              <CreativesUpload v-model="fileList" :fileList="fileList" :unfilteredCreatives="unfilteredCreatives"
                :receivedCreatives="receivedCreatives" :type="'image'" />
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad IAB Categories">
                    <a-tree checkable v-model="checkedComputedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                      optionFilterProp="children" />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Attributes">
                    <a-tree class="break-line" checkable v-model="checkedComputedAdKeys" :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData" @expand="onExpand" @select="onAdSelect" optionFilterProp="children" />
                  </a-form-item>
                </div>
              </div>

              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display
                      Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="!countriesList">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
      item.CC
    }})</span>
                      </label>
                      <span class="col-sm cpos-half">
                        <label class="mr-1 no-margin">Bid Adjustment:
                          <span class="valuePadding"><input type="number" min="0" max="100"
                              class="form-control bid-adjustment ml-1" @keyup="adjustBid($event, item, null)"
                              v-model="item.BidAdjustment">%</span>
                        </label>
                      </span>
                      <span class="col-sm cpos">
                        <label for="" class="mr-1">Calculated Bid: </label>
                        <span class="calculatedBid">{{
      typeof item.CalculatedBid === 'undefined' ? '' : Math.round(item.CalculatedBid * 100000) /
        100000
    }}</span>
                      </span>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Operating Systems" force-render>
                  <treeList :listData="bannerAdData.Os" type="os" v-model="OSData" :defaultVal="DefaultCPM"></treeList>
                </a-tab-pane>
                <a-tab-pane class="" style="overflow-x: scroll; overflow-y:hidden" key="3" tab="Browsers" force-render>
                  <treeList :listData="bannerAdData.Browsers" type="browser" v-model="BrowserData"
                    :defaultVal="DefaultCPM">
                  </treeList>
                </a-tab-pane>
              </a-tabs>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                    :class="'btn btn-' + (bannerAdData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-0 ml-lg-2'"
                    @click.prevent="toggleAdStatus">
                    {{ bannerAdData.Status == 0 ? 'Deactivate' : 'Activate' }} Banner Ad
                  </button>
                  <button type="button" class="btn px-5 ml-0 ml-lg-2" @click="goBack">Cancel</button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import adTreeData from '@/views/display/data/blockedAds.json'
import treeList from '@/components/custom/TreeList'
import adPositions from '@/views/display/data/adPosition.json'
import { getCreatives } from '@/api/display/creative.js'
import { getIabCategories } from '@/api/info'
import { getBannerAds, changeBannerAdStatus } from '@/api/display/bannerad'
import staticCountryData from '@/resources/countries.json'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}
const labelColCat = {
  xs: { span: 18 },
  sm: { span: 5 },
}
const wrapperColCat = {
  xs: { span: 24 },
  sm: { span: 10 },
}

export default {
  components: {
    treeList,
    CreativesUpload: () => import('@/components/custom/creatives/creativesUpload/index.vue'),
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  computed: {
    ...mapState(['info', 'bannerzone', 'bannerad', 'rtbzone', 'creative']),
    ...mapGetters('bannerzone', ['getBannerzones', 'getActiveBannerzones']),
    ...mapGetters('rtbzone', ['getRTBZones', 'getActiveRTBZones']),
    ...mapGetters('creative', ['getCreatives']),
    enabledCountries: function () {
      if (this.displayEnabledCountries === true) {
        return this.countriesList.filter(el => el.Enabled === true)
      } else if (this.filterSearch !== '') {
        return this.countriesList.filter(el => {
          return el.Country.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            el.CC.toLowerCase().indexOf(this.filterSearch.toLowerCase()) !== -1
        })
      }
      return this.countriesList
    },
    activeBannerzones: function () {
      return this.getActiveBannerzones
    },
    activeRTBZones: function () {
      return this.getActiveRTBZones
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    checkedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.checkedAds
      },
      // setter
      set: function (newValue) {
        this.checkedAds = newValue
      },
    },
    // countriesList: function () {
    //   let countriesList = []
    //   if (typeof this.info.countries !== 'undefined' && this.info.countries.length > 0) {
    //     this.info.countries.forEach(function (el) {
    //       const country = this.bannerAdData.Countries.filter(ob => ob.CC === el.CC)
    //       if (typeof country[0] !== 'undefined') {
    //         if (typeof country[0].Enabled === 'undefined') country[0].Enabled = true
    //         countriesList.push(country[0])
    //       } else {
    //         // calculated bid should be connected to default input
    //         countriesList.push({
    //           Country: el.Country,
    //           CC: el.CC,
    //           BidAdjustment: 100,
    //           CalculatedBid: this.bannerAdData.DefaultCPM,
    //           Enabled: false,
    //         })
    //       }
    //     }.bind(this))
    //   }
    //   // Search filter
    //   if (countriesList.length > 0 && this.bidAdjCountryFilter !== '') {
    //     countriesList = countriesList.filter(el => {
    //       return el.Country.toLowerCase().indexOf(this.bidAdjCountryFilter.toLowerCase()) !== -1 ||
    //         el.CC.toLowerCase().indexOf(this.bidAdjCountryFilter.toLowerCase()) !== -1
    //     })
    //   }
    //   // Display Enabled filter
    //   if (countriesList.length > 0 && this.displayEnabledCountries) {
    //     countriesList = countriesList.filter(el => {
    //       return el.Enabled === true
    //     })
    //   }
    //   return countriesList
    // },

    // bannerAdData: function() {
    //   let bannerAdData = this.$store.getters['bannerad/getBannerAdData'](this.$route.params.id)
    //   if (typeof bannerAdData === 'undefined') {
    //     this.$store.dispatch('bannerad/LOAD_ADS')
    //     bannerAdData = { Countries: [], Browser: [], Os: [] }
    //   }
    //   if (bannerAdData.DefaultCPM !== 'undefined' && !isNaN(parseFloat(bannerAdData.DefaultCPM))) {
    //     bannerAdData.DefaultCPM = Math.round((bannerAdData.DefaultCPM + Number.EPSILON) * 1000000) / 1000000
    //   }
    //   return bannerAdData
    // },
    DefaultCPM: {
      get: function () {
        return this.bannerAdData.DefaultCPM
      },
      set: function (newValue) {
        if (!isNaN(parseFloat(newValue))) this.bannerAdData.DefaultCPM = parseFloat(newValue)
      },
    },
  },
  data() {
    return {
      countries: staticCountryData,
      form: this.$form.createForm(this),
      countriesList: [],
      selectAllCountries: false,
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      bannerPosition: null,
      adPositions: adPositions,
      treeData: [],
      adTreeData: adTreeData,
      fileList: [],
      adCreated: false,
      bannerAdData: null,
      creatives: [],
      unfilteredCreatives: [],
      filterSearch: '',
      receivedCreatives: [],
      unformattedResponse: [],
      bidAdjCountryColumns: [
        {
          title: 'Country',
          dataIndex: 'Country',
          key: 'Country',
        },
        {
          title: 'Enabled',
          dataIndex: 'Enabled',
          scopedSlots: { customRender: 'Enabled' },
        },
        {
          title: 'CC',
          dataIndex: 'CC',
          key: 'CC',
        },
        {
          title: 'Bid Adjustment',
          dataIndex: 'BidAdjustment',
          scopedSlots: { customRender: 'BidAdjustment' },
        },
        {
          title: 'Calculated Bid',
          dataIndex: 'CalculatedBid',
          scopedSlots: { customRender: 'CalculatedBid' },
        },
      ],
      columns: [
        {
          title: 'Preview',
          dataIndex: 'preview',
          key: 'preview',
        },
        // { title: 'Age', dataIndex: 'age', key: 'age' },
        {
          title: 'Action',
          dataIndex: '',
          align: 'center',
          key: 'x',
          scopedSlots: { customRender: 'action' },
        },
      ],
      bidAdjCountryFilter: '',
      countryPagination: { pageSize: 10 },
      OSData: null,
      BrowserData: null,
      labelCol,
      wrapperCol,
      labelColCat,
      wrapperColCat,
      displayEnabledCountries: false,
    }
  },
  methods: {
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    handleCountries() {
      this.countriesList.forEach(el => {
        el.Enabled = !this.selectAllCountries
      })
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.bannerAdData !== null && this.bannerAdData.Countries.length > 0) {
        for (const index in this.countriesList) {
          const country = this.bannerAdData.Countries.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    updateDefaultCPM(e) {
      this.DefaultCPM = e.target.value
      // const countryList = document.querySelectorAll('.countryList input[type=number]')
      // countryList.length > 0 && countryList.forEach(function(el, index) {
      //   this.adjustBid(null, this.countriesList[index], el)
      // }.bind(this))
      this.countriesList.forEach(el => {
        el.CalculatedBid = e.target.value * el.BidAdjustment / 100
        // this.bannerAdData.DefaultCPM = el.CalculatedBid
      })
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth
      this.height = document.documentElement.clientHeight
    },
    adjustBid(event, row, obj = null) {
      const object = obj !== null ? obj : event.target
      const newBid = !isNaN(parseFloat(object.value)) ? parseFloat(object.value) : 0
      let itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.countriesList.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.bannerAdData.DefaultCPM * row.BidAdjustment / 100),
          Enabled: false,
        })
      }
      itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].BidAdjustment = newBid
        this.countriesList[itemIndex].CalculatedBid = newBid === 0 ? 0 : this.bannerAdData.DefaultCPM * newBid / 100
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.bannerAdData.Countries.findIndex(el => el.CC === row.CC)
      if (itemIndex === -1) {
        this.bannerAdData.Countries.push({
          Country: row.Country,
          CC: row.CC,
          BidAdjustment: row.BidAdjustment,
          CalculatedBid: (this.bannerAdData.DefaultCPM * row.BidAdjustment / 100),
          Enabled: true,
        })
      } else {
        this.bannerAdData.Countries[itemIndex].Enabled = event.target.checked
      }
      // Recalculate bid
      const li = this.$_getContainer(event.target)
      const input = li.querySelector('input[type="number"]')
      this.adjustBid(null, row, input)
    },
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: 'bannerads' })
    },
    setAdPosition(key) {
      this.bannerPosition = key
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            Country: el.Country,
            CC: el.CC,
            BidAdjustment: el.BidAdjustment,
            CalculatedBid: el.CalculatedBid,
          })
        }
      })
      const oss = this.$_formatTreeData('os')
      const browsers = this.$_formatTreeData('browser')

      this.form.validateFields((err, values) => {
        if (!err) {
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.AdPosition = this.bannerPosition
          values.AdIabCategories = newArr
          values.AdAttributes = this.checkedAds
          values.Id = this.bannerAdData.Id
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          values.Creatives = []
          this.receivedCreatives.forEach(creative => {
            values.Creatives.push(creative.Id)
            this.adCreated = true
          })
          this.$store.dispatch('bannerad/UPDATE_AD', {
            id: this.bannerAdData.Id,
            payload: {
              ...values,
              DefaultCPM: this.DefaultCPM,
              Browsers: browsers,
              Os: oss,
              Countries: countries,
            },
            callback: function () {
              this.$router.push({ name: 'bannerads' })
            }.bind(this),
            // @click="$router.push('/ads')"
          })
        }
      })
    },
    $_loadCreatives() {
      getCreatives().then(response => {
        this.unfilteredCreatives = response
        response.forEach(el => {
          if (this.bannerAdData.Creatives.includes(el.Id)) {
            this.receivedCreatives.push(el)
          }
        })
      })
    },
    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: ss.BidAdjustment,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: ver.BidAdjustment,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: el.BidAdjustment,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    $_getContainer(el, tag = 'LI') {
      return el.tagName === tag ? el : this.$_getContainer(el.parentElement, tag)
    },
    defaultCpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPM can not be negative.')
      }
      message()
    },
    toggleAdStatus() {
      const status = this.bannerAdData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Banner Ad status?',
        content: h => <div>{status} Banner Ad <strong></strong>?</div>,
        onOk: function () {
          const newStatus = this.bannerAdData.Status === 0 ? 1 : 0
          changeBannerAdStatus({
            id: this.bannerAdData.Id,
            status: newStatus,
          }).then((response) => {
            this.bannerAdData.Status = response.Bannerad.Status
            Vue.prototype.$notification.success({
              message: (response.Bannerad.Status === 2 ? 'Banner Ad Deleted' : 'Status Changed'),
              description: (response.Bannerad.Status === 2 ? 'Banner Ad successfully deleted.' : 'Banner Ad status successfully changed.'),
            })
          })
        }.bind(this),
      })
    },
  },
  watch: {
    bannerAdData(val) {
      if (val !== undefined) {
        this.checkedKeys = val.AdIabCategories
        this.checkedComputedAdKeys = val.AdAttributes
        this.bannerPosition = val.AdPosition
      }
    },
    bannerPosition() {

    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions)
    // const bannerAdData = this.$store.getters['bannerad/getBannerAdData'](this.$route.params.id)
    // if (bannerAdData !== undefined) {
    //   this.bannerPosition = bannerAdData.AdPosition
    //   this.$_loadCreatives()
    // }
    // if (typeof bannerAdData === 'undefined') {
    //   this.$store.dispatch('bannerad/LOAD_ADS', this.$route.params.id).then(() => { this.$_loadCreatives() })
    // } else {
    //   this.checkedKeys = this.bannerAdData.AdIabCategories
    //   this.checkedComputedAdKeys = this.bannerAdData.AdAttributes
    // }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  created() {
    // this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.$store.dispatch('bannerzone/LOAD_BANNERZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    // this.$store.dispatch('creative/LOAD_CREATIVES')
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    getBannerAds(this.$route.params.id).then((response) => {
      this.bannerAdData = response
      this.$_loadCreatives()
      this.checkedKeys = this.bannerAdData.AdIabCategories
      this.checkedComputedAdKeys = this.bannerAdData.AdAttributes
      this.populateCountry()
    })
      .then(() => {
        this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
          res.forEach((el, index) => {
            const country = this.bannerAdData.Countries.filter(ob => ob.CC === el.CC)
            if (typeof country[0] !== 'undefined') {
              if (typeof country[0].Enabled === 'undefined') country[0].Enabled = true
              this.countriesList.push(country[0])
              return false
            }
            this.countriesList.push({
              Country: el.Country,
              CC: el.CC,
              Enabled: false,
              BidAdjustment: 100,
              CalculatedBid: this.bannerAdData.DefaultCPM,
            })
          })
          // this.populateCountry()
        })
      })
  },
}
</script>
<style lang="css" scoped>
.valuePadding {
  background-color: #ffffff;
  border-radius: 4px;
  padding-right: 5px;
  border: #e9edf3 1px solid;
}

.valuePadding input {
  border: none;
  background-color: transparent;
  margin-top: 0;
}

.valuePadding .bid-adjustment {
  padding-bottom: 0px;
  padding-top: 0px;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.break-line>>>li .ant-tree-node-content-wrapper {
  margin-bottom: 5px;
  padding-top: 4px;
  display: inline-block !important;
  white-space: pre-wrap !important;
  line-height: 15px !important;
  width: 90% !important;
}

.creative {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 3em;
  padding-right: 3em;
}

.pointer {
  cursor: pointer;
}

.box {
  height: 8em;
  width: 8em;
  /* border: 0; */
  overflow: none;
  padding: 0;
  background-color: #f0f2f4;
}

.selected-outline {
  outline: thick solid #e5471c
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.header-bottom {
  height: 5em;
  margin-top: 3em;
}

.select-container {
  display: flex;
  justify-content: center;
  color: #f0f2f4;
}

.header-split {
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.header-top {
  top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #557df6;
}

.header-bottom {
  bottom: 0;
  background-color: none;
}

.footer-split {
  display: inline-flex;
  align-self: flex-end;
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.footer-top {
  top: 63%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.footer-bottom {
  bottom: 0;
  background-color: none;
}

.sidebar-split {
  height: 8em;
  width: 3em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.sidebar-left {
  left: 63%;
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.sidebar-right {
  right: 0;
  background-color: none;
}

.above-split {
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.above-top {
  top: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #557df6;
}

.above-bottom {
  bottom: 0;
  background-color: none;
}

.below-split {
  display: inline-flex;
  align-self: flex-end;
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.below-top {
  top: 38%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #557df6;
}

.below-bottom {
  bottom: 0;
  background-color: none;
}

@media only screen and (min-width: 768px) {
  .w-45 {
    width: 45% !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

;

@media only screen and (max-width: 768px) {
  .mobile-mb {
    margin-bottom: 1em;
  }

  .-m {
    margin-right: -4rem;
  }

  .centered {
    justify-content: space-evenly;
  }

  .creative {
    display: flex;
    flex-direction: column;
  }
}

.box {
  height: 8em;
  width: 8em;
  border: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background: #f0f2f4;
}

;

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}
</style>
